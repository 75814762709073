import React, { useState, useContext } from "react";
import { debounce, classList } from "../../Utils";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { NavLink } from "react-router-dom";
import ScrollTo from "../common/ScrollTo";
import { FyneContext } from "../../FyneContext";

const TopBarBuilder = ({...props})=> {
  const config = useContext(FyneContext);
  console.log('FyneContext', {props,config});

  const [ state, setState ] = useState({
    isTop: true,
    isClosed: true
  });
//  let handleScrollRef;
//
//  componentDidMount() {
//    if (window) {
//      handleScrollRef = handleScroll();
//      window.addEventListener("scroll", handleScrollRef);
//    }
//  }
//
//  componentWillUnmount() {
//    if (window) {
//      window.removeEventListener("scroll", handleScrollRef);
//    }
//  }
//
//  handleScroll() {
//    return debounce(() => {
//      if (window) {
//        let isTop = window.scrollY < 100;
//        if (isTop !== state.isTop) {
//          setState({ isTop });
//        }
//      }
//    }, 20);
//  }

  const close = () => {
    setState({ isClosed: true });
  };
  //render() {
    let toggleIcon = state.isClosed ? "menu" : "close";
    return (
      <section
        className={classList({
          header: true,
          "header-fixed": !state.isTop,
          closed: state.isClosed
        })}
      >
        <div className="container header-container">
          <div className="brand">
            <img src={config.APP_BASE+"assets/images/logo-full.png"} alt="" />
          </div>
          <ul className="navigation">
            {/*  */}
            {/* <li> */}
              {/* <NavLink to="/Landing1">Landing1</NavLink> */}
            {/* </li> */}
            {/*  */}
            <li>
              <ScrollTo to="intro3" onScroll={close}>
                Home
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="service3" onScroll={close}>
                Service
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="service5" onScroll={close}>
                Features
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="pricing1" onScroll={close}>
                Pricing
              </ScrollTo>
            </li>
            <li>
              <ScrollTo to="contact1" onScroll={close}>
                Contact
              </ScrollTo>
            </li>
          </ul>
          <div className="m-auto" />
          <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  aria-label="Buy"
                  className=""
                >
                  <Icon className="mr-16">flight_takeoff</Icon>
                  Sign Up
                </Fab>
          <IconButton
            className="header__toggle"
            onClick={() => {
              setState({ isClosed: !state.isClosed });
            }}
          >
            <Icon>{toggleIcon}</Icon>
          </IconButton>
        </div>
      </section>
    );
  //}
}

export default TopBarBuilder;
