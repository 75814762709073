import React, { useState, useEffect } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import Theme from "./Theme";
import Landing1 from "./components/Landing1";
import Landing2 from "./components/Landing2";
import Landing3 from "./components/Landing3";
import BuilderLanding from "./components/BuilderLanding";
//import CleanerLanding from "./components/CleanerLanding";
//import PlumberLanding from "./components/PlumberLanding";
//import MoverLanding from "./components/MoverLanding";
//import WasteLanding from "./components/WasteLanding";
//import FloristLanding from "./components/FloristLanding";
//import TraderLanding from "./components/TraderLanding";
//import TankerLanding from "./components/TankerLanding";
//import Demo from "./components/Demo";
import "./styles/sass/app.scss";


import { useFyneAPI, FyneProvider, FyneContext, context } from "./fyneworks";

function App() {
  const { data:config, response, loading } = useFyneAPI(`/config`, [] );

  console.log('Render App', config, response, loading );

  let HomePage = Landing1;
  if(config && config.landing){
    switch(config.landing){
      case "builder": HomePage = BuilderLanding; break;
      //case "cleaner": HomePage = CleanerLanding; break;
      //case "plumber": HomePage = PlumberLanding; break;
      //case "mover": HomePage = MoverLanding; break;
      //case "waste": HomePage = WasteLanding; break;
      //case "florist": HomePage = FloristLanding; break;
      //case "trader": HomePage = TraderLanding; break;
      //case "tanker": HomePage = TankerLanding; break;
    }
  }

  return ( !!loading ? 'Loading' : (
      <MuiThemeProvider theme={Theme}>
        <FyneContext.Provider value={{...context, ...config}}>
          <FyneProvider>
            {/* <Router basename="/react-landing"> */}
            <Router basename="/">
              <Switch>
                
                <Route path="/" component={BuilderLanding} exact />
                {/* <Route path="/" component={Demo} exact /> */}
                {/* <Route path="/landing1" component={Landing1} /> */}
                {/* <Route path="/landing2" component={Landing2} /> */}
                {/* <Route path="/landing3" component={Landing3} /> */}
                {/* <Route path="/cleaners" component={CleanerLanding} /> */}
                {/* <Route path="/plumbers" component={PlumberLanding} /> */}
                {/* <Route path="/movers" component={MoverLanding} /> */}
                {/* <Route path="/WasteLanding" component={WasteLanding} /> */}
                {/* <Route path="/FloristLanding" component={FloristLanding} /> */}
                {/* <Route path="/TraderLanding" component={TraderLanding} /> */}
                {/* <Route path="/TankerLanding" component={TankerLanding} /> */}

                {/* <Route component={Error} /> */}
              </Switch>
            </Router>
          </FyneProvider>
        </FyneContext.Provider>
      </MuiThemeProvider>
    )
  );
}

export default App;
